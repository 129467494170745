import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Toaster from '@meforma/vue-toaster';

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

import Auth from "@/layouts/Auth.vue";

import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";



// routes

const routes = [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let app = createApp(App);
app.use(router);
app.use(Toaster);
app.mount('#app');
