<template>
  <main-content>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
          >
            <div class="rounded-t mb-0 px-6 py-6">
              <div class="text-center mb-3">
                <h6 class="text-blueGray-500 text-lg font-bold">
                  Setup SMS Alert
                </h6>
              </div>
              <hr class="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Phone number
                  </label>
                  <input
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="10 digit phone number no spaces"
                    v-model="phoneNum"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Pitchers <span v-if="preloading">⌚</span>
                  </label>
                  <ul>
                    <li>
                      <input
                        :value="pitcher"
                        @input="e => pitcher = e.target.value"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Clayton Kershaw"
                      /><br />
                    </li>
                    <div v-if="pitcher != null" class="search-list py-1">
                      <li
                        v-for="item in getPitchers()"
                        :key="item"
                        @click="addToSelected(item)"
                      >
                        <p
                          class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                          {{ item }}
                        </p>
                      </li>
                    </div>
                  </ul>
                </div>

                <!--
                <div class="relative mb-3">
                  <button
                    v-if="showMissing"
                    class="bg-blueGray-600 text-white active:bg-blueGray-300 text-xs font-bold px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-1/4 ease-linear transition-all duration-150"
                    type="button"
                    @click="flipShowMissing()"
                  >
                    Pitcher missing?
                  </button>
                  <div v-if="!showMissing">
                    <div class="rounded-t mb-0 px-6 py-6">
                      <div class="text-center mb-3">
                        <p class="text-blueGray-500 text-xs">
                          Your suggestions will be reviewed for correctness and
                          you will start receiving updates for the siggested
                          pitcher once verified.
                        </p>
                      </div>
                      <hr class="mt-6 border-b-1 border-blueGray-300" />
                    </div>
                    <label
                      class="block uppercase mt-3 text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Suggest pitchers
                    </label>
                    <ul>
                      <li>
                        <input
                          :value="pitcherSuggestion"
                          @input="e => pitcherSuggestion = e.target.value"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Type names of missing pitchers.."
                          v-on:keyup="handleSuggest"
                        /><br />
                      </li>
                    </ul>
                    <button
                      v-if="!showMissing"
                      class="bg-blueGray-600 mt-5 text-white active:bg-blueGray-300 text-xs font-bold px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-1/4 ease-linear transition-all duration-150"
                      type="button"
                      @click="flipShowMissing()"
                    >
                      Hide
                    </button>
                  </div>
                </div>
                -->

                <div
                  class="mt-3 inline-flex"
                  v-for="(item, index) in selected"
                  :key="item"
                >
                  <div
                    class="text-s mr-3 mb-1 items-center text-white font-bold bg-blueGray-600 leading-sm px-3 py-1 bg- text-blue-700 rounded"
                  >
                    {{ item }}
                    <i
                      @click="removeFromSelected(index)"
                      class="fas fa-times ml-3"
                    ></i>
                  </div>
                </div>

                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    @click="subscribe()"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-content>
</template>
<script>
import * as API from "../../sheets-api.js";

export default {
  data() {
    return {
      items: null,
      pitcher: null,
      selected: [],
      suggestions: [],
      phoneNum: null,
      showMissing: true,
      pitcherSuggestion: null,
      preloading: true,
    };
  },
  methods: {
    getPitchers() {
      if (!this.pitcher.length) {
        return [];
      }

      const input = this.pitcher.toLowerCase();
      return this.items.filter((item) => {
        let names = item.toLowerCase().split(" ");
        for (let name of names) {
          if (name.startsWith(input)) {
            return true;
          }
        }
      });
    },
    addToSelected(item) {
      this.selected.push(item);
      this.pitcher = null;
    },
    addToSuggestions(item) {
      this.suggestions.push(item);
      this.pitcherSuggestion = null;
    },
    removeFromSelected(index) {
      this.selected.splice(index, 1);
    },
    validatePhoneNum() {
      if (
        this.phoneNum == null ||
        isNaN(this.phoneNum) ||
        this.phoneNum.toString().length != 10
      )
        return false;
      return true;
    },
    async subscribe() {
      if (!this.validatePhoneNum()) {
        this.$toast.show(`Please enter a valid 10 digit phone number`, {
          position: "top",
          type: "error",
        });
        return;
      }
      if (this.selected.length < 1 && this.suggestions.length < 1) {
        this.$toast.show(`Please add a pitcher to subscribe`, {
          position: "top",
          type: "error",
        });
        return;
      }
      if (this.selected.length >= 1)
        await API.addSubscription(this.phoneNum, this.selected);
      if (this.suggestions.length >= 1)
        await API.suggestPitcher(this.suggestions);
      this.$toast.show(`You're subscribed!`, {
        position: "top",
        type: "success",
      });
      this.$router.push("/");
      return;
    },
    flipShowMissing() {
      this.showMissing = !this.showMissing;
    },
    handleSuggest(e) {
      console.log(e);
      if (e.keyCode === 13) {
        this.addToSelected(this.pitcherSuggestion);
        this.addToSuggestions(this.pitcherSuggestion);
      }
    },
  },
  async mounted() {
    await API.getAllPitchers().then((response) => {
      this.items = response;
      this.preloading = false;
    });
  },
};
</script>
<style scoped>
.search-list {
  max-height: 20vh;
  overflow-y: scroll;
}
</style>
