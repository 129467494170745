<template>
  <div>
    <navbar />
    <main>
      <div
        class="relative pt-16 pb-32 bg-blueGray-200 flex content-center items-center justify-center min-h-screen"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="background-image: url('https://images.unsplash.com/photo-1562711936-46f6760be439?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div>
                <h1 class="text-white font-semibold text-5xl">Don't miss a start!</h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  Receive a text the day your favorite pitcher is scheduled to take the mound
                </p>
                <button
                  @click="$router.push('/auth/register')"
                  class="bg-orange-500 text-white active:bg-blueGray-50 text-2xl font-semibold px-12 py-4 rounded hover:bg-blue outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mt-10 ease-linear w-44 transition-all duration-150"
                  type="button"
                >
                  Setup SMS Alert
                </button>
                <p class="mt-10 text-lg text-blueGray-200">
                  <i>It takes less than 10 seconds to setup your first alert</i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

export default {
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
