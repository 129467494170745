<template>
  <footer class="relative bg-blueGray-800 pb-2 pt-2 px-12">
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold">
          © {{ date }}
          <a
            href="https://twitter.com/rcyeske"
            class="text-blueGray-500 hover:text-blueGray-300"
          >
            Ryan Yeske
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
