import axios from 'axios';
const baseURL = 'https://u5wbro9w66.execute-api.us-east-2.amazonaws.com/prd/';

async function getAllPitchers() {
    return axios.create({ baseURL })
        .get("getAllPitchers").then(
            (response) => {
                return response.data.values;
            },
            (error) => {
                console.log(error);
            }
        );
}

async function addSubscription(phoneNumber, pitchers) {
    var data = JSON.stringify({
        "phone": phoneNumber,
        "pitcher": pitchers
    });
    console.log(data);

    var config = {
        method: 'post',
        url: baseURL + 'addSubscription',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    axios(config)
        .then(function () {
            console.log('success');
            return true;
        })
        .catch(function (error) {
            console.log(error.response);
            return false;
        });
}

async function suggestPitcher(pitchers) {
    var data = JSON.stringify({
        "pitcher": pitchers
    });

    var config = {
        method: 'post',
        url: baseURL + 'suggestPitcher',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    axios(config)
        .then(function () {
            console.log('success');
            return true;
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });
}

export { getAllPitchers, addSubscription, suggestPitcher };